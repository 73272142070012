import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "page d-flex flex-row flex-column-fluid"
}
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_3 = {
  id: "kt_content",
  class: "d-flex flex-column flex-column-fluid"
}
const _hoisted_4 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_AdminAside = _resolveComponent("AdminAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTToolbar = _resolveComponent("KTToolbar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTFooter = _resolveComponent("KTFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_TaskTemplatePanel = _resolveComponent("TaskTemplatePanel")!
  const _component_TaskPanel = _resolveComponent("TaskPanel")!
  const _component_MetricPanel = _resolveComponent("MetricPanel")!
  const _component_WorkPanel = _resolveComponent("WorkPanel")!
  const _component_FilePreview = _resolveComponent("FilePreview")!
  const _component_CodePanel = _resolveComponent("CodePanel")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_SelectWorkspace = _resolveComponent("SelectWorkspace")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    (_ctx.loaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.asideEnabled)
            ? (_openBlock(), _createBlock(_component_AdminAside, {
                key: 0,
                lightLogo: _ctx.themeLightLogo,
                darkLogo: _ctx.themeDarkLogo
              }, null, 8, ["lightLogo", "darkLogo"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_KTHeader, { title: _ctx.pageTitle }, null, 8, ["title"]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.subheaderDisplay)
                ? (_openBlock(), _createBlock(_component_KTToolbar, {
                    key: 0,
                    style: {"margin-bottom":"20px"},
                    breadcrumbs: _ctx.breadcrumbs,
                    title: _ctx.pageTitle
                  }, null, 8, ["breadcrumbs", "title"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", {
                  id: "kt_content_container",
                  class: _normalizeClass({
              'container-fluid': _ctx.contentWidthFluid,
              'container-xxl': !_ctx.contentWidthFluid,
            })
                }, [
                  _createVNode(_component_router_view)
                ], 2)
              ])
            ]),
            _createVNode(_component_KTFooter)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_KTScrollTop),
    _createVNode(_component_Panel, {
      show: _ctx.panelData.isShow,
      onClose: _ctx.closePanel
    }, {
      default: _withCtx(() => [
        (_ctx.panelData.isLoading)
          ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.panelData.type ==='TASK_TEMPLATE')
          ? _withDirectives((_openBlock(), _createBlock(_component_TaskTemplatePanel, {
              key: 1,
              id: _ctx.panelData.objectId
            }, null, 8, ["id"])), [
              [_directive_click_outside, _ctx.closePanel]
            ])
          : _createCommentVNode("", true),
        (_ctx.panelData.type ==='TASK')
          ? _withDirectives((_openBlock(), _createBlock(_component_TaskPanel, {
              key: 2,
              id: _ctx.panelData.objectId
            }, null, 8, ["id"])), [
              [_directive_click_outside, _ctx.closePanel]
            ])
          : _createCommentVNode("", true),
        (_ctx.panelData.type ==='METRIC')
          ? _withDirectives((_openBlock(), _createBlock(_component_MetricPanel, {
              key: 3,
              id: _ctx.panelData.objectId
            }, null, 8, ["id"])), [
              [_directive_click_outside, _ctx.closePanel]
            ])
          : _createCommentVNode("", true),
        (_ctx.panelData.type ==='WORK')
          ? _withDirectives((_openBlock(), _createBlock(_component_WorkPanel, {
              key: 4,
              id: _ctx.panelData.objectId
            }, null, 8, ["id"])), [
              [_directive_click_outside, _ctx.closePanel]
            ])
          : _createCommentVNode("", true),
        (_ctx.panelData.type ==='FILE')
          ? _withDirectives((_openBlock(), _createBlock(_component_FilePreview, {
              key: 5,
              id: _ctx.panelData.objectId
            }, null, 8, ["id"])), [
              [_directive_click_outside, _ctx.closePanel]
            ])
          : _createCommentVNode("", true),
        (_ctx.panelData.type ==='CODE')
          ? _withDirectives((_openBlock(), _createBlock(_component_CodePanel, {
              key: 6,
              id: _ctx.panelData.objectId
            }, null, 8, ["id"])), [
              [_directive_click_outside, _ctx.closePanel]
            ])
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show", "onClose"]),
    _createVNode(_component_BaseModal, {
      ref: "selectWorkspaceRef",
      name: "Select Workspace",
      id: "selectWorkspace",
      onOnHide: _ctx.hideBaseModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SelectWorkspace)
      ]),
      _: 1
    }, 8, ["onOnHide"])
  ], 64))
}