
import {computed, defineComponent, nextTick, onBeforeMount, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import KTToolbar from "@/layout/toolbar/Toolbar.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import KTLoader from "@/components/Loader.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {MenuComponent} from "@/assets/ts/components";
import {reinitializeComponents} from "@/core/plugins/keenthemes";
import {removeModalBackdrop} from "@/core/helpers/dom";
import {
  asideEnabled,
  contentWidthFluid,
  loaderEnabled,
  loaderLogo,
  subheaderDisplay,
  themeDarkLogo,
  themeLightLogo,
  toolbarDisplay,
} from "@/core/helpers/config";
import ApiService from "@/core/services/ApiService";
import {IBreadcrumbs} from "@/core/helpers/breadcrumb";
import {usePanelStore} from "@/store/panel.store";
import Panel from "@/components/base/panel/Panel.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import TaskTemplatePanel from "@/views/task/template/TaskTemplatePanel.vue";
import TaskPanel from "@/views/task/TaskPanel.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import SelectWorkspace from "@/views/workspace/SelectWorkspace.vue";
import MetricPanel from "@/views/metric/MetricPanel.vue";
import WorkPanel from "@/views/work/WorkPanel.vue";
import FilePreview from "@/views/doc/FilePreview.vue";
import CodePanel from "@/views/code/CodePanel.vue";
import AdminAside from "@/layout/aside/AdminAside.vue";

export default defineComponent({
  name: "AdminLayout",
  components: {
    AdminAside,
    CodePanel,
    FilePreview,
    WorkPanel,
    MetricPanel,
    SelectWorkspace,
    BaseModal,
    TaskPanel,
    TaskTemplatePanel,
    EntityLoading,
    Panel,
    KTHeader,
    KTFooter,
    KTScrollTop,
    KTLoader,
    KTToolbar
  },
  setup() {
    const store = useStore();
    const loaded = ref(false);
    const selectWorkspaceRef = ref()
    onBeforeMount(async () => {
      await store.dispatch(Actions.LOAD_PROFILE)
      loaded.value = true;
      MenuComponent.reinitialization();
      await nextTick(() => {
        reinitializeComponents();
      });
      setTimeout(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    })

    console.log("process")
    const route = useRoute();
    const router = useRouter();
    ApiService.setHeader();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.state.BreadcrumbsModule.title;
    });

    const breadcrumbs = computed<IBreadcrumbs[]>(() => {
      return store.state.BreadcrumbsModule.objectBreadcrumbs;
    });

    onMounted(() => {

      if (!store.getters.isUserAuthenticated) {
        router.push({name: "sign-in"});
      }

      nextTick(() => {
        reinitializeComponents();
      });

    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
        // check if current user is authenticated
        if (!store.getters.isUserAuthenticated) {
          router.push({name: "sign-in"});
        }

        nextTick(() => {
          reinitializeComponents();
        });
        removeModalBackdrop();
      }
    );

    const panelStore = usePanelStore();
    const panelData = computed(() => panelStore.data);
    const modalData = computed(() => panelStore.selectWorkspace);
    const hideBaseModal = () => {
      panelStore.setSelectWorkspace(false)
    }
    watch(() => modalData.value.isShow, cb => {
      if (cb) {
        selectWorkspaceRef.value.showBaseModal();
      } else {
        selectWorkspaceRef.value.hideBaseModal();
      }
    })

    const closePanel = () => {
      panelStore.hidePanel();
    }

    return {
      loaded,
      closePanel,
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      panelData,
      hideBaseModal,
      selectWorkspaceRef,
    };
  },
});
