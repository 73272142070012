
import {defineComponent} from "vue";
import {useI18n} from "vue-i18n/index";
import {asideTheme} from "@/core/helpers/config";
import AdminMenu from "./AdminMenu.vue";

export default defineComponent({
  name: "AdminAside",
  components: {
    AdminMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const {t} = useI18n();

    return {
      asideTheme,
      t,
    };
  },
});
